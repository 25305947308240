import React, { useContext, useState } from 'react'
import { contextStore } from '../../store/context';
import { Layout, Menu } from 'antd'
import type { MenuProps } from 'antd';
import {
    UserOutlined,
    DashboardOutlined,
    DeploymentUnitOutlined,
    BranchesOutlined,
    DatabaseOutlined,

} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout


type Props = {}

export const Navigation = (props: Props) => {
    const { me, setCurrentPage } = useContext(contextStore)
    const [collapsed, setCollapsed] = useState(window.screen.width < 992);
    const [openKeys, setOpenKeys] = useState(["reports"]);

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };
    const navigate = useNavigate()

    const defaultNavigationItems: MenuProps['items'] = [{
        key: "reports",
        label: "分析報告",
        icon: <DashboardOutlined />,
        onClick: () => {
            setCurrentPage({
                label: "分析報告"
            })
            navigate("/reports")
        }
    }, {
        key: "tracks",
        label: "手動追蹤號碼",
        icon: <BranchesOutlined />,
        onClick: () => {
            setCurrentPage({
                label: "手動追蹤號碼"
            })
            navigate("/tracks")
        }
    }, {
        key: "history-data",
        label: "歷史開牌結果",
        icon: <DatabaseOutlined />,
        onClick: () => {
            setCurrentPage({
                label: "歷史開牌結果"
            })
            navigate("/history-data")
        }
    }, {
        key: "maximum-history",
        label: "歷史最久開牌",
        icon: <DatabaseOutlined />,
        onClick: () => {
            setCurrentPage({
                label: "歷史最久開牌"
            })
            navigate("/maximum-history")
        }
    }, {
        key: "admin",
        style: {
            visibility: me?.role === "admin" && me?.id === "1" ? "visible" : "hidden",
        },
        label: "管理員",
        icon: <DeploymentUnitOutlined />,
        children: [{
            key: "users",
            label: "會員管理",
            icon: <UserOutlined />,
            onClick: () => {
                setCurrentPage({
                    label: "會員管理"
                })
                navigate("/admin/users")
            }
        }]

    }

    ]

    if (!me) {
        return null
    }


    return <Sider
        style={{
            overflow: 'auto',
            left: 0,
            top: 0,
            bottom: 0,
        }}
        className="site-layout-background"
        collapsed={collapsed}
        onCollapse={setCollapsed}
        collapsible
        collapsedWidth={window.innerWidth < 640 ? 40 : 80}
    >
        <Menu
            mode="inline"
            defaultSelectedKeys={["reports"]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}

            defaultOpenKeys={['reports']}
            items={defaultNavigationItems}
            theme="dark"
        />
    </Sider>;
}