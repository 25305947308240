import { useContext, useEffect, useState } from "react";
import { CreateTrackDocument, CreateTrackInput, CreateTrackMutation, CreateTrackMutationVariables, DeleteTrackDocument, DeleteTrackMutation, DeleteTrackMutationVariables, GameType, ListTracksInput, TracksDocument, TracksQuery, TracksQueryVariables, UpdateTrackDocument, UpdateTrackInput, UpdateTrackMutation, UpdateTrackMutationVariables } from "../../graphql/generated";
import { BaseActionType } from "../../store/baseActions";
import { client } from "../../store/client";
import { contextStore } from "../../store/context";
import { hasError } from "../utils/errors";
import { message } from "antd";

export function useTracksCRUD() {
    const { tracks, tracksDispatch } = useContext(contextStore)
    const [listTracksInput, setListTracksInput] = useState<ListTracksInput>({})
    const [createTrackInput, setCreateTrackInput] = useState<CreateTrackInput>({
        gameType: GameType.Today539,
        starsNumber: 2,
        series: [],
        comment: "",
    })
    const [loading, setLoading] = useState(false)

    const del = (id: string, name?: string) => {
        if (!id) {
            message.error("id is empty")
            return
        }
        let deleted = `ID: ${id}`
        if (name) {
            deleted = name
        }
        if (!window.confirm(`確定刪除 ${deleted} ?`)) {
            return
        }
        client.mutate<DeleteTrackMutation, DeleteTrackMutationVariables>({
            mutation: DeleteTrackDocument,
            variables: { id, }
        })
            .then(({ data, errors }) => {
                if (hasError(data, errors) || !data) {
                    return
                }
                tracksDispatch({
                    type: BaseActionType.Delete,
                    payload: data.deleteTrack,
                })
                message.success("刪除成功")
            })
    }

    const create = (callback?: () => void) => {
        client.mutate<CreateTrackMutation, CreateTrackMutationVariables>({
            mutation: CreateTrackDocument,
            variables: { input: createTrackInput }
        })
            .then(({ data, errors }) => {
                if (hasError(data, errors) || !data) {
                    return
                }
                tracksDispatch({
                    type: BaseActionType.Create,
                    payload: data.createTrack,
                })
                message.success("新增成功")
            })
            .finally(() => {
                if (callback) {
                    callback()
                }
            })
    }

    const update = (input: UpdateTrackInput) => {
        client.mutate<UpdateTrackMutation, UpdateTrackMutationVariables>({
            mutation: UpdateTrackDocument,
            variables: {
                input,
            }
        })
            .then(({ data, errors }) => {
                if (hasError(data, errors) || !data) {
                    return
                }
                tracksDispatch({
                    type: BaseActionType.Update,
                    payload: data.updateTrack,
                })
                message.success("更新成功")
            })
    }

    const list = () => {
        setLoading(true)
        client.query<TracksQuery, TracksQueryVariables>({
            query: TracksDocument,
            variables: {
                input: listTracksInput,
            }
        })
            .then(({ data, errors }) => {
                if (hasError(data, errors) || !data) {
                    return
                }
                tracksDispatch({
                    type: BaseActionType.List,
                    payload: data.tracks,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        list()
    }, [listTracksInput])

    return {
        del,
        update,
        create,
        list,
        listTracksInput,
        setListTracksInput,
        setCreateTrackInput,
        createTrackInput,
        tracks,
        loading,
    }
}