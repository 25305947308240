import {
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons'
import { Space } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { contextStore } from '../../store/context'
import './MyAvatar.css'

type Props = {}

export const MyAvatar: FunctionComponent = (props: Props) => {
    const { me, setCurrentPage, setMe } = useContext(contextStore)
    const navigate = useNavigate()

    if (!me) {
        return <div className='MyAvatar'><UserOutlined /></div>
    }
    return <div className='MyAvatar'>
        <Space size="large">
            <a className='User' onClick={() => {
                setCurrentPage({ label: "個人設定" })
                navigate('/my-setting')
            }}><Space><UserOutlined />{me.name}</Space></a>

            <a className='Logout'
                onClickCapture={() => {
                    localStorage.removeItem("accessToken")
                }}
                onClick={() => {
                    setMe(null)
                    navigate('/')
                }}
            >
                <Space>
                    <LogoutOutlined />
                    登出
                </Space>
            </a>
        </Space>
    </div>
}