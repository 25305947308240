import { useContext, useEffect, useState } from "react";
import { UpdateMeDocument, UpdateMeMutation, UpdateMeMutationVariables } from "../../graphql/generated";
import { client } from "../../store/client";
import { contextStore } from "../../store/context";
import { hasError } from "../utils/errors";
import { MeDocument, MeQuery, MeQueryVariables, UpdateUserSelfInput } from "../../graphql/generated";

export function useMySettingCRUD() {
    const { me, setMe } = useContext(contextStore)
    const [updateUserSelfInput, setUpdateUserSelfInput] = useState<UpdateUserSelfInput>({})


    const update = (input: UpdateUserSelfInput, onSuccess?: () => void) => {
        client.mutate<UpdateMeMutation, UpdateMeMutationVariables>({
            mutation: UpdateMeDocument,
            variables: {
                input,
            }
        })
            .then(({ data, errors }) => {
                if (hasError(data, errors) || !data) {
                    return
                }
                setMe(data.updateMe)
                if (onSuccess !== undefined) {
                    onSuccess()
                }
            })
    }

    const get = () => {
        client.query<MeQuery, MeQueryVariables>({
            query: MeDocument,
        })
        .then(({ data, errors }) => {
            if (hasError(data, errors) || !data) {
                return
            }
            setMe(data.me)
        })
    }

    useEffect(() => {
        get()
    }, [])

    return {
        me,
        setMe,
        update,
        get,
        updateUserSelfInput,
        setUpdateUserSelfInput,
    }
}