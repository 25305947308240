import { Select } from 'antd'
import React, { FunctionComponent } from 'react'

type Props = {
    starsNumber?: number
    onSelect: (starsNumber?: number) => void
    allowClear?: boolean
}

export const StarsNumberSelect: FunctionComponent<Props> = ({ onSelect, allowClear, starsNumber }) => {
    return <Select
        value={starsNumber}
        allowClear={allowClear}
        options={[
            {
                value: 2,
                label: "2星"
            }, {
                value: 3,
                label: "3星"
            }, {
                value: 4,
                label: "4星"
            }
        ]}
        onChange={starsNumber => {
            onSelect(starsNumber)
        }}
    />
}
