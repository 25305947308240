import React, { FunctionComponent, useState } from 'react'
// import formStyles from '../Forms.module.css'
import styles from './HistoryDataPage.module.css'
import { GameType, HistoryData, useHistoryDatasQuery } from '../../graphql/generated'
import { Divider, Form, Space, message } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { GameTypesSelect } from '../../components/UI/GameTypesSelect/GameTypesSelect'

type Props = {}

const HistoryDataPage: FunctionComponent<Props> = () => {
    const [currentGameType, setCurrentGameType] = useState<GameType>(GameType.Today539)
    const {data, loading} = useHistoryDatasQuery({
        variables: {
            gameType: currentGameType,
        },
        onError: err => {
            message.error(err.message)
        }
    })

    const columns: ColumnsType<HistoryData> = [{
        title: "開獎日",
        dataIndex: "openedAt",
        render: (_, record) => {
            return record.openedAt.toLocaleDateString()
        }
    }, {
        title: "期號",
        dataIndex: "lotteryId"
    }, {
        title: "獎號",
        dataIndex: "lotteryResult",
        render: (_, record) => {
            return <Space>{
                record.lotteryResult.map(num => {
                    return <div>{num}</div>
                }

                )}</Space>
        }
    }
]
    return <div className={styles.HistoryDataPage}>
        <Form>
            <Form.Item label="遊戲類型">
                <GameTypesSelect 
                    gameType={currentGameType}
                    onSelect={gameType => {
                        setCurrentGameType(gameType ?? GameType.Today539)
                    }}
                />
            </Form.Item>

        </Form>

        <Divider />
        <Table 
            columns={columns}
            dataSource={data?.historyDatas}
            loading={loading}
            pagination={{
                pageSize: 100,
            }}
        />
    </div>
}

export default HistoryDataPage