
import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ReportsPage from './containers/ReportsPage/ReportsPage'
import TracksPage from './containers/TracksPage/TracksPage'
import HistoryDataPage from './containers/HistoryDataPage/HistoryDataPage'
import MySettingPage from './containers/MySettingPage/MySettingPage'
import MaximumHistoryPage from './containers/MaximumHistoryPage/MaximumHistoryPage'
import UsersPage from './containers/AdminPage/UsersPage/UsersPage'

export const AllRoutes = () => {
    return <Routes>
            <Route path="reports" element={<ReportsPage />} />
    <Route path="tracks" element={<TracksPage />} />
    <Route path="history-data" element={<HistoryDataPage />} />
    <Route path="my-setting" element={<MySettingPage />} />
    <Route path="maximum-history" element={<MaximumHistoryPage />} />
    <Route path="admin">
        <Route path="users" element={<UsersPage />} />
    </Route>
    </Routes>
}