import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  FileData: string;
  IP: string;
  Time: Date;
  UUID: string;
};

export type Auth = {
  accessExpireAt: Scalars['Time'];
  accessToken: Scalars['UUID'];
};

export type CreateAuthInput = {
  account: Scalars['String'];
  password: Scalars['String'];
};

export type CreateTrackInput = {
  comment: Scalars['String'];
  gameType: GameType;
  series: Array<Scalars['Int']>;
  starsNumber: Scalars['Int'];
};

export type CreateUserInput = {
  account: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  role: Role;
};

export type DeleteResponse = {
  id: Scalars['ID'];
};

export enum GameType {
  Bigloto = 'bigloto',
  Dayday = 'dayday',
  Power = 'power',
  Sixhongkong = 'sixhongkong',
  Today539 = 'today539'
}

export type HistoryData = {
  gameType: GameType;
  id: Scalars['ID'];
  lotteryId: Scalars['String'];
  lotteryResult: Array<Scalars['Int']>;
  openedAt: Scalars['Time'];
};

export type ListReportsInput = {
  gameType: GameType;
};

export type ListTracksInput = {
  gameType?: InputMaybe<GameType>;
  seriesSize?: InputMaybe<Scalars['Int']>;
  starsNumber?: InputMaybe<Scalars['Int']>;
};

export type MaximumHistory = {
  gameType: GameType;
  lotteryId: Scalars['String'];
  maxUnopenedTimes: Scalars['Int'];
  series: Array<Scalars['Int']>;
  starsNumber: Scalars['Int'];
};

export type Mutation = {
  createAuth: Auth;
  createTrack: Track;
  deleteTrack: DeleteResponse;
  updateMe: User;
  updateTrack: Track;
};


export type MutationCreateAuthArgs = {
  input: CreateAuthInput;
};


export type MutationCreateTrackArgs = {
  input: CreateTrackInput;
};


export type MutationDeleteTrackArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMeArgs = {
  input: UpdateUserSelfInput;
};


export type MutationUpdateTrackArgs = {
  input: UpdateTrackInput;
};

export type Query = {
  gameTypes: Array<GameType>;
  historyDatas: Array<HistoryData>;
  maximumHistories: Array<MaximumHistory>;
  me: User;
  reports: Array<Report>;
  test: Scalars['Boolean'];
  tracks: Array<Track>;
  user: User;
  users: Array<User>;
};


export type QueryHistoryDatasArgs = {
  gameType: GameType;
};


export type QueryReportsArgs = {
  input: ListReportsInput;
};


export type QueryTracksArgs = {
  input: ListTracksInput;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type Report = {
  latestLotteryId: Scalars['String'];
  maxUnopenedTimes: Array<Scalars['Int']>;
  seriesSize: Scalars['Int'];
  starsContents: Array<Scalars['String']>;
  starsNumbers: Array<Scalars['Int']>;
};

export enum ReportType {
  Avg = 'avg',
  Latest = 'latest'
}

export enum Role {
  Admin = 'admin',
  User = 'user'
}

export type Track = {
  comment: Scalars['String'];
  createdAt: Scalars['Time'];
  gameType: GameType;
  id: Scalars['ID'];
  latestLotteryId?: Maybe<Scalars['String']>;
  series: Array<Scalars['Int']>;
  starsNumber: Scalars['Int'];
  unopenedTimes: Scalars['Int'];
  updatedAt: Scalars['Time'];
};

export type UpdateTrackInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateUserInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  resetPassword?: InputMaybe<Scalars['Boolean']>;
  unlockLoginFails?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserSelfInput = {
  newPassword?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  repeatNewPassword?: InputMaybe<Scalars['String']>;
};

export type User = {
  account: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lastLoginAt: Scalars['Time'];
  lastLoginIP: Scalars['IP'];
  loginFailedAts: Array<Scalars['Time']>;
  loginFailedIPs: Array<Scalars['IP']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Role;
  updatedAt: Scalars['Time'];
};

export type InitLoginQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type InitLoginQueryQuery = { me: { id: string, createdAt: Date, updatedAt: Date, account: string, password?: string | null, name: string, isActive: boolean, role: Role, lastLoginIP: string, lastLoginAt: Date, loginFailedIPs: Array<string>, loginFailedAts: Array<Date>, comment: string } };

export type CreateAuthMutationVariables = Exact<{
  input: CreateAuthInput;
}>;


export type CreateAuthMutation = { createAuth: { accessToken: string, accessExpireAt: Date } };

export type UpdateMeMutationVariables = Exact<{
  input: UpdateUserSelfInput;
}>;


export type UpdateMeMutation = { updateMe: { id: string, createdAt: Date, updatedAt: Date, account: string, password?: string | null, name: string, isActive: boolean, role: Role, lastLoginIP: string, lastLoginAt: Date, loginFailedIPs: Array<string>, loginFailedAts: Array<Date>, comment: string } };

export type CreateTrackMutationVariables = Exact<{
  input: CreateTrackInput;
}>;


export type CreateTrackMutation = { createTrack: { id: string, createdAt: Date, updatedAt: Date, gameType: GameType, starsNumber: number, series: Array<number>, latestLotteryId?: string | null, unopenedTimes: number, comment: string } };

export type UpdateTrackMutationVariables = Exact<{
  input: UpdateTrackInput;
}>;


export type UpdateTrackMutation = { updateTrack: { id: string, createdAt: Date, updatedAt: Date, gameType: GameType, starsNumber: number, series: Array<number>, latestLotteryId?: string | null, unopenedTimes: number, comment: string } };

export type DeleteTrackMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTrackMutation = { deleteTrack: { id: string } };

export type GameTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GameTypesQuery = { gameTypes: Array<GameType> };

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = { test: boolean };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { user: { id: string, createdAt: Date, updatedAt: Date, account: string, password?: string | null, name: string, isActive: boolean, role: Role, lastLoginIP: string, lastLoginAt: Date, loginFailedIPs: Array<string>, loginFailedAts: Array<Date>, comment: string } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { users: Array<{ id: string, createdAt: Date, updatedAt: Date, account: string, password?: string | null, name: string, isActive: boolean, role: Role, lastLoginIP: string, lastLoginAt: Date, loginFailedIPs: Array<string>, loginFailedAts: Array<Date>, comment: string }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { id: string, createdAt: Date, updatedAt: Date, account: string, password?: string | null, name: string, isActive: boolean, role: Role, lastLoginIP: string, lastLoginAt: Date, loginFailedIPs: Array<string>, loginFailedAts: Array<Date>, comment: string } };

export type HistoryDatasQueryVariables = Exact<{
  gameType: GameType;
}>;


export type HistoryDatasQuery = { historyDatas: Array<{ id: string, openedAt: Date, lotteryId: string, gameType: GameType, lotteryResult: Array<number> }> };

export type ReportsQueryVariables = Exact<{
  input: ListReportsInput;
}>;


export type ReportsQuery = { reports: Array<{ seriesSize: number, starsNumbers: Array<number>, starsContents: Array<string>, maxUnopenedTimes: Array<number>, latestLotteryId: string }> };

export type TracksQueryVariables = Exact<{
  input: ListTracksInput;
}>;


export type TracksQuery = { tracks: Array<{ id: string, createdAt: Date, updatedAt: Date, gameType: GameType, starsNumber: number, series: Array<number>, latestLotteryId?: string | null, unopenedTimes: number, comment: string }> };

export type MaximumHistoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type MaximumHistoriesQuery = { maximumHistories: Array<{ gameType: GameType, starsNumber: number, maxUnopenedTimes: number, series: Array<number>, lotteryId: string }> };


export const InitLoginQueryDocument = gql`
    query InitLoginQuery {
  me {
    id
    createdAt
    updatedAt
    account
    password
    name
    isActive
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useInitLoginQueryQuery__
 *
 * To run a query within a React component, call `useInitLoginQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitLoginQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitLoginQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitLoginQueryQuery(baseOptions?: Apollo.QueryHookOptions<InitLoginQueryQuery, InitLoginQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitLoginQueryQuery, InitLoginQueryQueryVariables>(InitLoginQueryDocument, options);
      }
export function useInitLoginQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitLoginQueryQuery, InitLoginQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitLoginQueryQuery, InitLoginQueryQueryVariables>(InitLoginQueryDocument, options);
        }
export type InitLoginQueryQueryHookResult = ReturnType<typeof useInitLoginQueryQuery>;
export type InitLoginQueryLazyQueryHookResult = ReturnType<typeof useInitLoginQueryLazyQuery>;
export type InitLoginQueryQueryResult = Apollo.QueryResult<InitLoginQueryQuery, InitLoginQueryQueryVariables>;
export const CreateAuthDocument = gql`
    mutation CreateAuth($input: CreateAuthInput!) {
  createAuth(input: $input) {
    accessToken
    accessExpireAt
  }
}
    `;
export type CreateAuthMutationFn = Apollo.MutationFunction<CreateAuthMutation, CreateAuthMutationVariables>;

/**
 * __useCreateAuthMutation__
 *
 * To run a mutation, you first call `useCreateAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthMutation, { data, loading, error }] = useCreateAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthMutation, CreateAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthMutation, CreateAuthMutationVariables>(CreateAuthDocument, options);
      }
export type CreateAuthMutationHookResult = ReturnType<typeof useCreateAuthMutation>;
export type CreateAuthMutationResult = Apollo.MutationResult<CreateAuthMutation>;
export type CreateAuthMutationOptions = Apollo.BaseMutationOptions<CreateAuthMutation, CreateAuthMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($input: UpdateUserSelfInput!) {
  updateMe(input: $input) {
    id
    createdAt
    updatedAt
    account
    password
    name
    isActive
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const CreateTrackDocument = gql`
    mutation CreateTrack($input: CreateTrackInput!) {
  createTrack(input: $input) {
    id
    createdAt
    updatedAt
    gameType
    starsNumber
    series
    latestLotteryId
    unopenedTimes
    comment
  }
}
    `;
export type CreateTrackMutationFn = Apollo.MutationFunction<CreateTrackMutation, CreateTrackMutationVariables>;

/**
 * __useCreateTrackMutation__
 *
 * To run a mutation, you first call `useCreateTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrackMutation, { data, loading, error }] = useCreateTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrackMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrackMutation, CreateTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrackMutation, CreateTrackMutationVariables>(CreateTrackDocument, options);
      }
export type CreateTrackMutationHookResult = ReturnType<typeof useCreateTrackMutation>;
export type CreateTrackMutationResult = Apollo.MutationResult<CreateTrackMutation>;
export type CreateTrackMutationOptions = Apollo.BaseMutationOptions<CreateTrackMutation, CreateTrackMutationVariables>;
export const UpdateTrackDocument = gql`
    mutation UpdateTrack($input: UpdateTrackInput!) {
  updateTrack(input: $input) {
    id
    createdAt
    updatedAt
    gameType
    starsNumber
    series
    latestLotteryId
    unopenedTimes
    comment
  }
}
    `;
export type UpdateTrackMutationFn = Apollo.MutationFunction<UpdateTrackMutation, UpdateTrackMutationVariables>;

/**
 * __useUpdateTrackMutation__
 *
 * To run a mutation, you first call `useUpdateTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrackMutation, { data, loading, error }] = useUpdateTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrackMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrackMutation, UpdateTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrackMutation, UpdateTrackMutationVariables>(UpdateTrackDocument, options);
      }
export type UpdateTrackMutationHookResult = ReturnType<typeof useUpdateTrackMutation>;
export type UpdateTrackMutationResult = Apollo.MutationResult<UpdateTrackMutation>;
export type UpdateTrackMutationOptions = Apollo.BaseMutationOptions<UpdateTrackMutation, UpdateTrackMutationVariables>;
export const DeleteTrackDocument = gql`
    mutation DeleteTrack($id: ID!) {
  deleteTrack(id: $id) {
    id
  }
}
    `;
export type DeleteTrackMutationFn = Apollo.MutationFunction<DeleteTrackMutation, DeleteTrackMutationVariables>;

/**
 * __useDeleteTrackMutation__
 *
 * To run a mutation, you first call `useDeleteTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrackMutation, { data, loading, error }] = useDeleteTrackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrackMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrackMutation, DeleteTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrackMutation, DeleteTrackMutationVariables>(DeleteTrackDocument, options);
      }
export type DeleteTrackMutationHookResult = ReturnType<typeof useDeleteTrackMutation>;
export type DeleteTrackMutationResult = Apollo.MutationResult<DeleteTrackMutation>;
export type DeleteTrackMutationOptions = Apollo.BaseMutationOptions<DeleteTrackMutation, DeleteTrackMutationVariables>;
export const GameTypesDocument = gql`
    query GameTypes {
  gameTypes
}
    `;

/**
 * __useGameTypesQuery__
 *
 * To run a query within a React component, call `useGameTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGameTypesQuery(baseOptions?: Apollo.QueryHookOptions<GameTypesQuery, GameTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameTypesQuery, GameTypesQueryVariables>(GameTypesDocument, options);
      }
export function useGameTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameTypesQuery, GameTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameTypesQuery, GameTypesQueryVariables>(GameTypesDocument, options);
        }
export type GameTypesQueryHookResult = ReturnType<typeof useGameTypesQuery>;
export type GameTypesLazyQueryHookResult = ReturnType<typeof useGameTypesLazyQuery>;
export type GameTypesQueryResult = Apollo.QueryResult<GameTypesQuery, GameTypesQueryVariables>;
export const TestDocument = gql`
    query Test {
  test
}
    `;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQuery(baseOptions?: Apollo.QueryHookOptions<TestQuery, TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestQuery, TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestQuery, TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestQuery, TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestQueryResult = Apollo.QueryResult<TestQuery, TestQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    id
    createdAt
    updatedAt
    account
    password
    name
    isActive
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    createdAt
    updatedAt
    account
    password
    name
    isActive
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    createdAt
    updatedAt
    account
    password
    name
    isActive
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const HistoryDatasDocument = gql`
    query HistoryDatas($gameType: GameType!) {
  historyDatas(gameType: $gameType) {
    id
    openedAt
    lotteryId
    gameType
    lotteryResult
  }
}
    `;

/**
 * __useHistoryDatasQuery__
 *
 * To run a query within a React component, call `useHistoryDatasQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryDatasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryDatasQuery({
 *   variables: {
 *      gameType: // value for 'gameType'
 *   },
 * });
 */
export function useHistoryDatasQuery(baseOptions: Apollo.QueryHookOptions<HistoryDatasQuery, HistoryDatasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryDatasQuery, HistoryDatasQueryVariables>(HistoryDatasDocument, options);
      }
export function useHistoryDatasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryDatasQuery, HistoryDatasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryDatasQuery, HistoryDatasQueryVariables>(HistoryDatasDocument, options);
        }
export type HistoryDatasQueryHookResult = ReturnType<typeof useHistoryDatasQuery>;
export type HistoryDatasLazyQueryHookResult = ReturnType<typeof useHistoryDatasLazyQuery>;
export type HistoryDatasQueryResult = Apollo.QueryResult<HistoryDatasQuery, HistoryDatasQueryVariables>;
export const ReportsDocument = gql`
    query Reports($input: ListReportsInput!) {
  reports(input: $input) {
    seriesSize
    starsNumbers
    starsContents
    maxUnopenedTimes
    latestLotteryId
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportsQuery(baseOptions: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const TracksDocument = gql`
    query Tracks($input: ListTracksInput!) {
  tracks(input: $input) {
    id
    createdAt
    updatedAt
    gameType
    starsNumber
    series
    latestLotteryId
    unopenedTimes
    comment
  }
}
    `;

/**
 * __useTracksQuery__
 *
 * To run a query within a React component, call `useTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTracksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTracksQuery(baseOptions: Apollo.QueryHookOptions<TracksQuery, TracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
      }
export function useTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TracksQuery, TracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
        }
export type TracksQueryHookResult = ReturnType<typeof useTracksQuery>;
export type TracksLazyQueryHookResult = ReturnType<typeof useTracksLazyQuery>;
export type TracksQueryResult = Apollo.QueryResult<TracksQuery, TracksQueryVariables>;
export const MaximumHistoriesDocument = gql`
    query MaximumHistories {
  maximumHistories {
    gameType
    starsNumber
    maxUnopenedTimes
    series
    lotteryId
  }
}
    `;

/**
 * __useMaximumHistoriesQuery__
 *
 * To run a query within a React component, call `useMaximumHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaximumHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaximumHistoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaximumHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<MaximumHistoriesQuery, MaximumHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaximumHistoriesQuery, MaximumHistoriesQueryVariables>(MaximumHistoriesDocument, options);
      }
export function useMaximumHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaximumHistoriesQuery, MaximumHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaximumHistoriesQuery, MaximumHistoriesQueryVariables>(MaximumHistoriesDocument, options);
        }
export type MaximumHistoriesQueryHookResult = ReturnType<typeof useMaximumHistoriesQuery>;
export type MaximumHistoriesLazyQueryHookResult = ReturnType<typeof useMaximumHistoriesLazyQuery>;
export type MaximumHistoriesQueryResult = Apollo.QueryResult<MaximumHistoriesQuery, MaximumHistoriesQueryVariables>;