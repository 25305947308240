import { Button, Form, Input, message, Typography } from 'antd'
import React, { FunctionComponent } from 'react'
import { UpdateUserSelfInput } from '../../../graphql/generated'
import formStyles from '../../Forms.module.css'
// import styles from './ChangePasswordPage.module.css'

type Props = {
    updateUserSelfInput: UpdateUserSelfInput,
    setUpdateUserSelfInput: React.Dispatch<React.SetStateAction<UpdateUserSelfInput>>
    update: (input: UpdateUserSelfInput, onSuccess?: () => void) => void,
}

const ChangePasswordPage: FunctionComponent<Props> = ({updateUserSelfInput, setUpdateUserSelfInput, update}) => {
    const disable = !updateUserSelfInput.oldPassword || !updateUserSelfInput.newPassword || !updateUserSelfInput.repeatNewPassword
    const submit = () => {
        if (updateUserSelfInput.newPassword !== updateUserSelfInput.repeatNewPassword) {
            message.error("重複密碼不符")
            return
        }
        if (!window.confirm("確定修改密碼?")) {
            return
        }
        update(updateUserSelfInput, () => {
            window.location.reload()
        })
    }
    return <Form className={formStyles.FormFrame}>
        <Typography.Title level={4}>修改密碼</Typography.Title>
        <Form.Item
            label="原密碼"
            required
        >
            <Input.Password onInput={e => {
                setUpdateUserSelfInput({
                    ...updateUserSelfInput,
                    oldPassword: e.currentTarget.value,
                })
            }} />
        </Form.Item>
        <Form.Item
            label="新密碼"
            required
        >
            <Input.Password onInput={e => {
                setUpdateUserSelfInput({
                    ...updateUserSelfInput,
                    newPassword: e.currentTarget.value,
                })
            }} />
        </Form.Item>
        <Form.Item
            label="重複新密碼"
            required
        >
            <Input.Password onInput={e => {
                setUpdateUserSelfInput({
                    ...updateUserSelfInput,
                    repeatNewPassword: e.currentTarget.value,
                })
            }} 
            onPressEnter={() => {
                if (!disable) {
                    submit()
                }
            }}
            />
        </Form.Item>

        <Button type="primary" disabled={disable} onClick={() => {
            submit()
        }}>修改</Button>
    </Form>
}

export default ChangePasswordPage