import {
    EditTwoTone,
    SaveTwoTone,
    CloseSquareTwoTone,
} from '@ant-design/icons';
import { Button, Input, Space } from 'antd'
import React, { useState } from 'react'

type Props = {
    defaultValue: string
    onSave?: (newValue: string) => void
    isURL?: boolean
    disabled?: boolean
}

export const EditableText: React.FC<Props> = ({ defaultValue, onSave, isURL, disabled: readonly }) => {
    const [newValue, setNewValue] = useState(defaultValue)
    const [editing, setEditing] = useState(false)
    if (!editing || readonly) {
        return <Space>
            {isURL ?
                <a href={defaultValue} target="_blank" rel="noreferrer">{defaultValue}</a> :
                <div>{defaultValue}</div>
            }

            {readonly ? null :
            <Button id="edit-button" size="small" onClick={() => {
                setEditing(true)
                setNewValue(defaultValue)
            }} type="link">
                <EditTwoTone twoToneColor={"green"}></EditTwoTone>
            </Button>}
        </Space>
    }
    return <Space>
        <Input value={newValue} onInput={e => setNewValue(e.currentTarget.value)} onPressEnter={() => {
            if (!onSave) {
                return
            }
            onSave(newValue)
            setEditing(false)
        }}></Input>
        <Button id="save-button" type="link" size="small">
            <SaveTwoTone onClick={() => {
                if (!onSave) {
                    return
                }
                onSave(newValue)
                setEditing(false)
            }} twoToneColor="gray" />
        </Button>
        <Button id="cancel-button" type="link" size="small">
            <CloseSquareTwoTone onClick={() => {
                if (newValue !== defaultValue) {
                    const yes = window.confirm(`是否放棄編輯?`)
                    if (!yes) {
                        return
                    }
                }
                setNewValue(defaultValue)
                setEditing(false)
            }} twoToneColor="red" />
        </Button>
    </Space>
}