import { Reducer } from "react"
import { BaseActions, BaseActionType, DataWithID } from "./baseActions"

export function createBaseReducer<T extends DataWithID>(): Reducer<T[], BaseActions<T>> {
    return (state, action) => {    
        switch (action.type) {
            case BaseActionType.List:
                return action.payload
            case BaseActionType.Get:
                return [
                    ...state.filter(row => row.id !== action.payload.id),
                    action.payload,
                ]
            case BaseActionType.Create:
                return [
                    action.payload,
                    ...state,
                ]
            case BaseActionType.Update:
                return state.map(row => {
                    if (row.id === action.payload.id) {
                        return action.payload
                    }
                    return row

                })
            case BaseActionType.ChangeWeight:
                const newState = [...state]
                if (action.payload.length === 2) {
                    let currentIndex: number = -1
                    let swappedIndex: number = -1
                    const [current, swapped] = action.payload
                    newState.forEach((row, i) => {
                        if (row.id === current.id) {
                            currentIndex = i
                        }
                        if (row.id === swapped.id) {
                            swappedIndex = i
                        }
                    })

                    if (currentIndex !== -1) {
                        newState[currentIndex] = swapped
                    }
                    if (swappedIndex !== -1) {
                        newState[swappedIndex] = current
                    }
                }

                return newState
            case BaseActionType.Delete:
                return state.filter(user => user.id !== action.payload.id)
            default:
                throw new Error('no action matched');
        }
    }
}
