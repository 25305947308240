import { Select } from 'antd'
import React, { FunctionComponent } from 'react'
import { GameType } from '../../../graphql/generated'

type Props = {
    gameType?: GameType
    onSelect: (gameType?: GameType) => void
    allowClear?: boolean
}

const gameTypeMap = {
    [GameType.Bigloto]: "大樂透",
    [GameType.Dayday]: "天天樂",
    [GameType.Today539]: "今彩539",
    [GameType.Power]: "威力彩",
    [GameType.Sixhongkong]: "六合彩",
}

export function gameTypeToZhTW(gameType: GameType) {
    return gameTypeMap[gameType]
}

export const GameTypesSelect: FunctionComponent<Props> = ({ onSelect, allowClear, gameType }) => {
    return <Select
        value={gameType}
        allowClear={allowClear}
        options={[
            {
                value: GameType.Bigloto,
                label: "大樂透"
            }, {
                value: GameType.Dayday,
                label: "天天樂"
            }, {
                value: GameType.Today539,
                label: "今彩539"
            }, {
                value: GameType.Power,
                label: "威力彩"
            }, {
                value: GameType.Sixhongkong,
                label: "六合彩"
            },
        ]}
        onChange={newGameType => {
            onSelect(newGameType)
        }}
    />
}
