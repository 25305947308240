import { Button, Col, Divider, Form, message, Modal, Row, Typography } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import React, { FunctionComponent, useState } from 'react'
import { GameTypesSelect } from '../../components/UI/GameTypesSelect/GameTypesSelect'
import { GameType, ListReportsInput, Report, useReportsQuery } from '../../graphql/generated'
import formStyles from '../Forms.module.css'
import { translateGameType } from '../utils/translation'
import styles from './ReportsPage.module.css'

type Props = {}

type SeriesContent = {
    title: string
    content: string
}

const ReportsPage: FunctionComponent<Props> = () => {
    const [listReportsInput, setListReportsInput] = useState<ListReportsInput>({
        gameType: GameType.Today539,
    })
    const [seriesContent, setSeriesContent] = useState<SeriesContent>()

    const { data, loading } = useReportsQuery({
        variables: {
            input: listReportsInput,
        },
        onError: err => {
            message.error(err.message)
        }
    })
    const latestLotteryId = data?.reports[0].latestLotteryId
    const buttonType = "dashed"

    const columns: ColumnsType<Report> = [{
        title: "號碼數",
        dataIndex: "seriesSize",
        render: (_, record) => {
            return `${record.seriesSize}顆`
        }
    },
    {
        title: "2星",
        dataIndex: "starsContents",
        render: (_, record) => {
            return <Button
                disabled={record.starsContents[0] === "無"}
                type={buttonType}
                onClick={() => {
                    setSeriesContent({
                        title: `[${translateGameType(listReportsInput.gameType)}] 2星 - ${record.seriesSize}顆`,
                        content: record.starsContents[0],
                    })
                }}
            >{record.maxUnopenedTimes[0] + "期"}</Button>
        }
    },
    {
        title: "3星",
        dataIndex: "starsContents",
        render: (_, record) => {
            return <Button
                disabled={record.starsContents[1] === "無"}
                type={buttonType}
                onClick={() => {
                    setSeriesContent({
                        title: `[${translateGameType(listReportsInput.gameType)}] 3星 - ${record.seriesSize}顆`,
                        content: record.starsContents[1],
                    })
                }}
            >{record.maxUnopenedTimes[1] + "期"}</Button>
        }
    },
    {
        title: "4星",
        dataIndex: "starsContents",
        render: (_, record) => {
            return <Button
                disabled={record.starsContents[2] === "無"}
                type={buttonType}
                onClick={() => {
                    setSeriesContent({
                        title: `[${translateGameType(listReportsInput.gameType)}] 4星 - ${record.seriesSize}顆`,
                        content: record.starsContents[2],
                    })
                }}
            >{record.maxUnopenedTimes[2] + "期"}</Button>
        }
    },
    ]
    return <div className={styles.ReportsPage}>
        <Col lg={12}>
            <Form className={formStyles.FormFrame}>
                <Typography.Title level={4}>搜尋條件</Typography.Title>
                <GameTypesSelect
                    gameType={listReportsInput.gameType}
                    onSelect={gameType => {
                        if (!gameType) {
                            return
                        }
                        setListReportsInput({
                            ...listReportsInput,
                            gameType,
                        })
                    }}
                />
            </Form>
        </Col>

        <Divider />

        <Row justify='center'>
            <Typography.Title level={3}>{`最新一期已開期號: ${latestLotteryId}`}</Typography.Title>
        </Row>

        <Table
            size='small'
            columns={columns}
            dataSource={data?.reports}
            loading={loading}
            scroll={{
                x: true,
            }}
            pagination={{
                pageSize: 20,
            }}
        />

        <Modal
            width={window.innerWidth < 640 ? "100%" : "max(40%, 640px)"}
            visible={!!seriesContent}
            onOk={() => {
                setSeriesContent(undefined)
            }}
            onCancel={() => {
                setSeriesContent(undefined)
            }}
            cancelButtonProps={{ hidden: true }}
        >
            <Row justify='center'>
                <Typography.Title level={3}>{seriesContent?.title}</Typography.Title>
            </Row>
            <Divider />
            <Col>
                {seriesContent?.content.split("\n").map(line => {
                    return <Row justify='center'>
                        <Typography.Title level={window.innerWidth < 640 ? 5 : 3}>
                            {line}
                        </Typography.Title>
                    </Row>
                })}
            </Col>
        </Modal>
    </div>
}

export default ReportsPage