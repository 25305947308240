import { createContext } from "react";
import { User } from "../graphql/generated";

import { TracksActions, TracksState } from "./reducers/tracks";
import { UsersActions, UsersState } from "./reducers/users";

const VoidFunction = () => { }

export type TranslationMap = { [key: string]: string }
export type CurrentPage = { label: string, subTitle?: string }

export interface ContextState {

    tracks: TracksState
    tracksDispatch: React.Dispatch<TracksActions>
    users: UsersState
    usersDispatch: React.Dispatch<UsersActions>


    // 玩家資訊
    me: User | null
    setMe: React.Dispatch<User | null>

    currentPage: CurrentPage
    setCurrentPage: React.Dispatch<CurrentPage>
}


export const initState: ContextState = {
    me: null,
    setMe: VoidFunction,
    currentPage: { label: "" },
    setCurrentPage: VoidFunction,

    tracks: [],
    tracksDispatch: VoidFunction,
    users: [],
    usersDispatch: VoidFunction,
}


export const contextStore = createContext<ContextState>(initState)
