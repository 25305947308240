import { GameType } from "../../graphql/generated";

export function translateGameType(gameType: GameType): string {
    switch (gameType) {
        case GameType.Today539:
            return "今彩539"
        case GameType.Bigloto:
            return "大樂透"
        case GameType.Dayday:
            return "天天樂"
        case GameType.Power:
            return "威力彩"
        case GameType.Sixhongkong:
            return "六合彩"
        default:
            break;
    }
    return gameType
}