import { Scalars } from "../graphql/generated";

export enum BaseActionType {
    List,
    Get,
    Update,
    ChangeWeight,
    Create,
    Delete,
}

export interface DataWithID {
    id: string;
}

interface ListAction<T> {
    type: typeof BaseActionType.List,
    payload: T[]
}

interface GetAction<T> {
    type: typeof BaseActionType.Get,
    payload: T
}

interface UpdateAction<T> {
    type: typeof BaseActionType.Update,
    payload: T
}

interface ChangeWeightAction<T> {
    type: typeof BaseActionType.ChangeWeight,
    payload: T[]
}

interface CreateAction<T> {
    type: typeof BaseActionType.Create,
    payload: T
}

interface DeleteAction {
    type: typeof BaseActionType.Delete,
    payload: {
        id: Scalars['ID']
    }
}


export type BaseActions<T extends DataWithID> = ListAction<T> | GetAction<T> | UpdateAction<T> | ChangeWeightAction<T> | CreateAction<T> | DeleteAction
