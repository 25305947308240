import React, { FunctionComponent } from 'react'
// import formStyles from '../../Forms.module.css'
import styles from './UsersPage.module.css'

type Props = {}

const UsersPage: FunctionComponent<Props> = () => {
    return <div className={styles.UsersPage}>UsersPage</div>
}

export default UsersPage