import './App.css'
import { Spin, Layout } from 'antd';
import React, { useEffect, Suspense, useReducer } from 'react';
import { useState } from 'react';
import { InitLoginQueryDocument, InitLoginQueryQuery, InitLoginQueryQueryVariables, User } from './graphql/generated';
import { client } from './store/client';
import { ContextState, contextStore, CurrentPage } from './store/context';
import { Navigation } from './components/Navigation/Navigation';
import { ContentPageHeader } from './components/ContentPageHeader/ContentPageHeader';
import { AllRoutes } from './AllRoutes';
import { MyHeader } from './components/MyHeader/MyHeader';

import { tracksReducer, initTracksState } from './store/reducers/tracks';
import { usersReducer, initUsersState } from './store/reducers/users';

import { Login } from './components/Login/Login';

const { Content } = Layout;

export const App: React.FC = () => {
  const [me, setMe] = useState<User | null>(null)
  const [currentPage, setCurrentPage] = useState<CurrentPage>({ label: "" })

  const [tracks, tracksDispatch] = useReducer(tracksReducer, initTracksState)
  const [users, usersDispatch] = useReducer(usersReducer, initUsersState)

  const context: ContextState = {
    me,
    setMe,
    currentPage,
    setCurrentPage,

    tracks,
    tracksDispatch,
    users,
    usersDispatch,

  }

  useEffect(() => {
    if (me || !localStorage.getItem("accessToken")) {
      return
    }
    client.query<InitLoginQueryQuery, InitLoginQueryQueryVariables>({
      query: InitLoginQueryDocument,
    })
      .then(({ data, errors }) => {
        if (errors) {
          console.log(errors)
          return
        }
        console.log(data)
        setMe(data.me)
      }
      )
  }, [me])

  if (!me) {
    return <Login />
  }

  return <contextStore.Provider value={context}>
    <Layout>

      <Layout>
        <Navigation />
        <Layout style={{ minHeight: '100vh' }}>
          <MyHeader />

          <Content
            className="site-layout-background"
            style={{
              padding: "4px 24px",
              // margin: 0,
              // minHeight: 640,
            }}
          >
            <ContentPageHeader />
            <Suspense fallback={<Spin />}>
              <AllRoutes />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </contextStore.Provider>
};
