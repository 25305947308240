import { Header } from 'antd/lib/layout/layout';
import React from 'react'
import { MyAvatar } from '../MyAvatar/MyAvatar';

type Props = {}

export const MyHeader = (props: Props) => {
  return <Header className="Header" style={{
    backgroundColor: "#123",
  }}>
  {/* <Menu
    theme="dark"
    mode="horizontal"
    defaultSelectedKeys={['2']}
    items={new Array(15).fill(null).map((_, index) => {
      const key = index + 1;
      return {
        key,
        label: `nav ${key}`,
      };
    })}
  /> */}
  <MyAvatar />

</Header>
}