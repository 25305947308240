import { Input } from 'antd'
import React, { FunctionComponent } from 'react'

type Props = {
    seriesSize?: number
    onSelect: (seriesSize?: number) => void
    allowClear?: boolean
}

export const SeriesSizeInput: FunctionComponent<Props> = ({ onSelect, allowClear, seriesSize }) => {
    return <Input
        allowClear={allowClear}
        value={seriesSize}
        type="number"
        onChange={e => {
            if (!e.target.value) {
                onSelect(undefined)
                return
            }
            const newValue = Math.min(parseInt(e.target.value), 20)
            onSelect(newValue)
        }}
    />
}
