import { PageHeader } from 'antd'
import React, { useContext } from 'react'
import { contextStore } from '../../store/context'


export const ContentPageHeader = () => {
    const { currentPage } = useContext(contextStore)
    return <PageHeader
        className="site-page-header"
        title={currentPage.label}
        subTitle={currentPage.subTitle}
    />
}
