import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions, NormalizedCacheObject } from '@apollo/client';
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from '@apollo/client/link/context';
import { DateTimeResolver } from 'graphql-scalars'
import { createTransformerLink } from './transforms';

export const BASE_URL = process.env.NODE_ENV === "production" ? '': process.env.REACT_APP_BASE_URL || "http://localhost:8080"
export const ENDPOINT = BASE_URL + '/api/v0/graphql'

const transformersLink = createTransformerLink([{
  parseKey: key => key.endsWith('At'),
  parseValue: value => {
    if (value === undefined || value === null) {
      return value
    }
    return DateTimeResolver.parseValue(value)
  },
}
])

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
}

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });


const cache = new InMemoryCache({addTypename: false});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: false
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
});

const httpLink = new HttpLink({
    uri: ENDPOINT,
});

export const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    link: transformersLink.concat(authLink).concat(retryLink).concat(httpLink),
    defaultOptions: defaultOptions,
});
