import { Descriptions, Form, List } from 'antd'
import React, { FunctionComponent } from 'react'
import { UpdateUserSelfInput, User } from '../../../graphql/generated'
import formStyles from '../../Forms.module.css'
// import styles from './MyProfilePage.module.css'

type Props = {
    me: User | null,
    // updateUserSelfInput: UpdateUserSelfInput,
    // setUpdateUserSelfInput: React.Dispatch<React.SetStateAction<UpdateUserSelfInput>>,
    update: (input: UpdateUserSelfInput, callback?: () => void) => void,
}

const MyProfilePage: FunctionComponent<Props> = ({ me, update }) => {

    if (!me) {
        return null
    }
    return <Form className={formStyles.FormFrame}>
        <Descriptions title="個人資訊" bordered
            size="small"
            contentStyle={{ border: "2px solid #555555" }}
            labelStyle={{ border: "2px solid #555555", backgroundColor: "#aaaaaa" }}
        >
            <Descriptions.Item
                label="角色"
            >
                {me.role}
            </Descriptions.Item>
            <Descriptions.Item
                label="註冊日"
            >
                {me.createdAt.toLocaleDateString()}
            </Descriptions.Item>
            <Descriptions.Item
                label="登入IP"
            >
                {`${me.lastLoginIP} (${me.lastLoginAt.toLocaleString()})`}
            </Descriptions.Item>
            <Descriptions.Item
                label="登入失敗紀錄"
            >
                <List
                    bordered
                    size='small'
                >
                    {me.loginFailedIPs.map((ip, idx) => {
                        return <List.Item>{`${ip}\t(${new Date(me.loginFailedAts[idx]).toLocaleString()})`}</List.Item>
                    })}
                </List>
            </Descriptions.Item>
        </Descriptions>
    </Form>
}

export default MyProfilePage