import React, { Fragment, CSSProperties } from 'react';

import './FormBox.css'
import { Backdrop } from '../Backdrop/Backdrop';

interface FormBoxProps {
    show: boolean
    formBoxClosed: () => void
    style?: CSSProperties
    children: React.ReactNode
}

export const FormBox: React.FC<FormBoxProps> = ({show, formBoxClosed, style, children}) => {
    return (
        <Fragment>
            <Backdrop show={show} clicked={formBoxClosed} />
            <div
                className="FormBox"
                style={{
                    transform: show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: show ? '1' : '0',
                    ...style,
                }}>
                {children}
            </div>
        </Fragment>
    )
}
