import React, { FunctionComponent, useState } from 'react'
import formStyles from '../Forms.module.css'
import styles from './MaximumHistoryPage.module.css'
import { GameType, MaximumHistory, useMaximumHistoriesQuery } from '../../graphql/generated'
import Table, { ColumnsType } from 'antd/lib/table'
import { GameTypesSelect, gameTypeToZhTW } from '../../components/UI/GameTypesSelect/GameTypesSelect'
import { Divider, Form, Typography } from 'antd'
import { StarsNumberSelect } from '../../components/UI/StarsNumberSelect/StarsNumberSelect'
import { SeriesSizeInput } from '../../components/UI/SeriesSizeInput/SeriesSizeInput'

type Props = {}

const MaximumHistoryPage: FunctionComponent<Props> = () => {
    const {data, loading} = useMaximumHistoriesQuery()
    const [filterGameType, setFilterGameType] = useState<GameType>()
    const [filterStarsNumber, setStarsNumber] = useState<number>()
    const [filterSeriesSize, setSeriesSize] = useState<number>()

    let filtered: {
        gameType: GameType;
        starsNumber: number;
        maxUnopenedTimes: number;
        series: number[];
        lotteryId: string;
    }[] = data?.maximumHistories ?? []

    if (filterGameType) {
        filtered = filtered.filter(row => row.gameType === filterGameType)
    }
    if (filterStarsNumber) {
        filtered = filtered.filter(row => row.starsNumber === filterStarsNumber)
    }
    if (filterSeriesSize) {
        filtered = filtered.filter(row => row.series.length === filterSeriesSize)
    }

    const columns: ColumnsType<MaximumHistory> = [{
        title: "遊戲類型",
        dataIndex: "gameType",
        render: (_, record) => {
            return gameTypeToZhTW(record.gameType)
        }
    }, {
        title: "星數",
        dataIndex: "starsNumber"
    }, {
        title: "顆數",
        dataIndex: "seriesSize",
        render: (_, record) => {
            return record.series.length
        }
    },{
        title: "最大未開期數",
        dataIndex: "maxUnopenedTimes",
    },{
        title: "期號",
        dataIndex: "lotteryId",
    },{
        title: "目標號碼",
        dataIndex: "series",
        render: (_, record) => {
            return record.series.join(", ")
        }
    },
]

    return <div className={styles.MaximumHistoryPage}>

        <Form className={formStyles.FormFrame}>
            <Typography.Title level={4}>搜尋條件</Typography.Title>
            <Form.Item label="遊戲類型">
                <GameTypesSelect
                    gameType={filterGameType}
                    allowClear
                    onSelect={gameType => {
                        setFilterGameType(gameType)
                    }}
                />
            </Form.Item>
            <Form.Item label="星數">
                <StarsNumberSelect
                    starsNumber={filterStarsNumber}
                    allowClear
                    onSelect={starsNumber => {
                        setStarsNumber(starsNumber)
                    }}
                />
            </Form.Item>
            <Form.Item label="號碼數">
                <SeriesSizeInput
                    allowClear
                    seriesSize={filterSeriesSize}
                    onSelect={seriesSize => {
                        setSeriesSize(seriesSize)
                    }}
                />
            </Form.Item>
        </Form>

        <Divider />

        <Table 
            columns={columns}
            dataSource={filtered}
        />
    </div>
}

export default MaximumHistoryPage