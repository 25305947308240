import { DeleteTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Space, Tag, Typography } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import React, { FunctionComponent, useState } from 'react'
import { EditableText } from '../../components/UI/EditableText/EditableText'
import { GameTypesSelect } from '../../components/UI/GameTypesSelect/GameTypesSelect'
import { SeriesSizeInput } from '../../components/UI/SeriesSizeInput/SeriesSizeInput'
import { StarsNumberSelect } from '../../components/UI/StarsNumberSelect/StarsNumberSelect'
import { GameType, Track } from '../../graphql/generated'
import formStyles from '../Forms.module.css'
import { translateGameType } from '../utils/translation';
import { useTracksCRUD } from './TracksCRUD'
import styles from './TracksPage.module.css'

type Props = {}

const TracksPage: FunctionComponent<Props> = () => {
    const [seriesNum, setSeriesNum] = useState("")

    const crud = useTracksCRUD()

    const columns: ColumnsType<Track> = [{
        title: "遊戲類型",
        dataIndex: "gameType",
        render: (_, record) => {
            return translateGameType(record.gameType)
        }
    }, {
        title: "星數",
        dataIndex: "starsNumber"
    }, {
        title: "最新期號",
        dataIndex: "latestLotteryId"
    }, {
        title: "追蹤號碼",
        dataIndex: "series",
        render: (_, record) => {
            return <Space>{
                record.series.map(num => {
                    return <div>{num}</div>
                }

                )}</Space>
        }
    }, {
        title: "未開期數",
        dataIndex: "unopenedTimes"
    }, {
        title: "新增日",
        dataIndex: "createdAt",
        render: (_, record) => {
            return record.createdAt.toLocaleDateString()
        }
    }, {
    }, {
        title: "更新時間",
        dataIndex: "createdAt",
        render: (_, record) => {
            return record.updatedAt.toLocaleString()
        }
    }, {
        title: "備註",
        dataIndex: "comment",
        render: (_, record) => {
            return <EditableText
                defaultValue={record.comment}
                onSave={newValue => {
                    crud.update({
                        id: record.id,
                        comment: newValue,
                    })
                }}
            />
        }
    },
    {
        title: '操作',
        dataIndex: '_',
        key: 'operations',
        render: (_, record) => {
            return <Button onClick={() => crud.del(record.id, record.series.join(", "))} title="刪除追蹤">
                <DeleteTwoTone twoToneColor={"red"} />
            </Button>
        }
    },

    ]
    return <div className={styles.TracksPage}>
        <Row>
            <Col lg={8}>
                <Form className={formStyles.FormFrame}>
                    <Typography.Title level={4}>搜尋條件</Typography.Title>
                    <Form.Item label="遊戲類型">
                        <GameTypesSelect
                            gameType={crud.listTracksInput.gameType ?? undefined}
                            allowClear
                            onSelect={gameType => {
                                crud.setListTracksInput({
                                    ...crud.listTracksInput,
                                    gameType,
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="星數">
                        <StarsNumberSelect
                            starsNumber={crud.listTracksInput.starsNumber ?? undefined}
                            allowClear
                            onSelect={starsNumber => {
                                crud.setListTracksInput({
                                    ...crud.listTracksInput,
                                    starsNumber,
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="號碼數">
                        <SeriesSizeInput
                            allowClear
                            seriesSize={crud.listTracksInput.seriesSize ?? undefined}
                            onSelect={seriesSize => {
                                crud.setListTracksInput({
                                    ...crud.listTracksInput,
                                    seriesSize,
                                })
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>

            <Col lg={8}>
                <Form className={formStyles.FormFrame}>
                    <Typography.Title level={4}>新增追蹤</Typography.Title>
                    <Form.Item label="星數">
                        <StarsNumberSelect
                            starsNumber={crud.createTrackInput.starsNumber ?? undefined}
                            onSelect={starsNumber => {
                                crud.setCreateTrackInput({
                                    ...crud.createTrackInput,
                                    starsNumber: starsNumber ?? 2,
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="遊戲類型">
                        <GameTypesSelect
                            gameType={crud.createTrackInput.gameType ?? undefined}
                            allowClear
                            onSelect={newGameType => {
                                crud.setCreateTrackInput({
                                    ...crud.createTrackInput,
                                    gameType: newGameType ?? GameType.Today539,
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="追蹤號碼">
                        <Input
                            type="number"
                            value={seriesNum}
                            onChange={e => {
                                setSeriesNum(e.currentTarget.value)
                            }}
                            onPressEnter={e => {
                                const newValue = parseInt(e.currentTarget.value)
                                crud.setCreateTrackInput({
                                    ...crud.createTrackInput,
                                    series: [...crud.createTrackInput.series, newValue]
                                })
                                setSeriesNum("")
                            }}
                        />
                        <Space>
                            {crud.createTrackInput.series.map(s => {
                                return <Tag closable onClose={() => {
                                    crud.setCreateTrackInput({
                                        ...crud.createTrackInput,
                                        series: crud.createTrackInput.series.filter(value => value !== s),
                                    })
                                }}>
                                    {s}
                                </Tag>
                            })}
                        </Space>
                    </Form.Item>
                    <Form.Item label="備註">
                        <Input
                            onChange={e => {
                                crud.setCreateTrackInput({
                                    ...crud.createTrackInput,
                                    comment: e.currentTarget.value,
                                })
                            }}
                        />
                    </Form.Item>
                    <Button type="primary" onClick={() => {
                        crud.create()
                    }}>新增</Button>
                </Form>
            </Col>
        </Row>

        <Divider />

        <Table
            columns={columns}
            dataSource={crud.tracks}
            loading={crud.loading}
        />

    </div>
}

export default TracksPage