import { Col, Row } from 'antd'
import React, { FunctionComponent } from 'react'
// import formStyles from '../Forms.module.css'
import ChangePasswordPage from './ChangePasswordPage/ChangePasswordPage'
import MyProfilePage from './MyProfilePage/MyProfilePage'
import { useMySettingCRUD } from './MySettingCRUD'
import styles from './MySettingPage.module.css'

type Props = {}

const MySettingPage: FunctionComponent<Props> = () => {
    const crud = useMySettingCRUD()
    return <div className={styles.MySettingPage}>
        <Row>
            <Col lg={16}>
                <MyProfilePage {...crud}/>
            </Col>
            <Col>
                <ChangePasswordPage {...crud} />
            </Col>

        </Row>

    </div>
}

export default MySettingPage