import { ApolloLink, FetchResult } from '@apollo/client';
import { isPlainObject } from 'is-plain-object';

export type ParseKey = (key: string) => boolean
export type ParseValue = (value: any) => any
export type Transformers = {
    parseKey: ParseKey,
    parseValue: ParseValue,
}[]

function parseObjectValues(transformers: Transformers, object: any) {
    for (let key in object) {
        transformers.forEach(trans => {
            if (trans.parseKey(key)) {
                object[key] = trans.parseValue(object[key])
            }
        })
    }
}

function parseObject(transformers: Transformers, object: any) {
    if (!object) {
        return;
    }

    if (Array.isArray(object)) {
        object.forEach(row => parseObject(transformers, row));
        return;
    }

    if (!isPlainObject(object)) {
        return;
    }

    for (let key in object) {
        parseObject(transformers, object[key])
    }

    parseObjectValues(transformers, object)
}

function parseResponse(response: FetchResult, transformers: Transformers) {
    if (response.data) {
        for (let key in response.data) {
            console.log("parseObject")
            parseObject(transformers, response.data[key]);
        }

    }
    return response;
}

export function createTransformerLink(transformers: Transformers) {
    return new ApolloLink((operation, forward) => {

        return forward(operation).map(response =>
            parseResponse(response, transformers),
        );
    });
}