import {
    LoadingOutlined
} from '@ant-design/icons';
import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { FormBox } from '../../components/UI/FormBox/FormBox'
import { hasError } from '../../containers/utils/errors';
import { CreateAuthDocument, CreateAuthMutation, CreateAuthMutationVariables } from '../../graphql/generated'
import { client } from '../../store/client'

type Props = {}

export const Login: React.FC = (props: Props) => {
    const [account, setAccount] = useState("")
    const [password, setPassword] = useState("")
    const [isLoginging, setIsLogining] = useState(false)

    const onLogin = () => {
        setIsLogining(isLoginging)
        client.mutate<CreateAuthMutation, CreateAuthMutationVariables>({
            mutation: CreateAuthDocument,
            variables: {
                input: {
                    account,
                    password,
                }
            }
        })
            .then(({ data, errors }) => {
                if (errors && errors.length > 0) {
                    const errMsg = errors[0].message
                    // missing-input-secret	The secret parameter is missing.
                    // invalid-input-secret	The secret parameter is invalid or malformed.
                    // missing-input-response	The response parameter is missing.
                    // invalid-input-response	The response parameter is invalid or malformed.
                    // bad-request	The request is invalid or malformed.
                    // timeout-or-duplicate	The response is no longer valid: either is too old or has been used previously.
                    console.log("login error:", errors)
                    switch (errMsg) {
                        case "missing-input-secret":
                            alert("[錯誤L00] 伺服器設定異常, 請聯絡客服")
                            return
                        case "invalid-input-secret":
                            alert("[錯誤L01] 伺服器設定異常, 請聯絡客服")
                            return
                        case "missing-input-response":
                            alert("[錯誤L02] 伺服器設定異常, 請聯絡客服")
                            return
                        case "invalid-input-response":
                            alert("[錯誤L03] 無效的登入, 如有疑問, 請聯絡客服")
                            return
                        case "bad-request":
                            alert("[錯誤L04] 無效的登入, 如有疑問, 請聯絡客服")
                            return

                        case "timeout-or-duplicate":
                            alert("[錯誤L05] 憑證錯誤或逾期, 請再試一次")
                            window.location.reload()
                            return
                    }
                }
                if (hasError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }
                if (data) {
                    console.log("set accessToken", data.createAuth.accessToken)
                    localStorage.setItem("accessToken", data.createAuth.accessToken)
                    window.location.reload()
                }
            })
            .finally(() => {
                setIsLogining(false)
            })

    }
    return <React.Fragment>
        <FormBox show formBoxClosed={() => { }}>
            <Form>
                <label>{"帳號"}</label>
                <Input type="text" autoComplete='username' value={account} onChange={e => setAccount(e.currentTarget.value.trim())} />
                <label>{"密碼"}</label>
                <Input.Password autoComplete='current-password' value={password} onChange={e => setPassword(e.currentTarget.value)} onPressEnter={onLogin} />
                {isLoginging ?
                    <LoadingOutlined /> :
                    <Button type="primary" onClick={onLogin}>{`登入`}</Button>

                }
            </Form>
        </FormBox>
    </React.Fragment>
}
