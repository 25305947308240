import React, { FunctionComponent } from 'react';
import './Backdrop.css'

interface BackdropProps {
    clicked: () => void
    show: boolean
}

export const Backdrop: FunctionComponent<BackdropProps> = ({show, clicked}) => (
    show ? <div className="Backdrop" onClick={clicked}></div> : null
)
